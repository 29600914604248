@import '/src/styles/base';
.order-list-container {
  width: 100%;
  height: 100vh;
  padding: 32px 32px 0 32px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 30%;
  ::-webkit-scrollbar {
    display: none;
  }
  .container {
    box-sizing: border-box;
    border: none;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    .card-index {
      padding: 16px 50px 12px 40px;
      font-size: 16px;
      .card-index-state {
        display: flex;
        float: right;
        gap: 20px;
        .state-icon {
          display: flex;
          float: right;
          gap: 10px;
          .cooked {
            width: 40px !important;
            height: 40px !important;
          }
          .served {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }
    }
  }
  .contents {
    .order-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      .tag-order-no {
        display: flex;
        padding: 2px 8px;
        background: $color-tag-bg;
        border-radius: 100px;
        color: $color-tag-text;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
      }
      .order-no {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
    hr {
      border: 1px solid $color-hr-default;
      margin-top: 12px;
    }
    .order-list {
      height: 350px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 0px;
      overflow: hidden;
      overflow-y: scroll;
      .item-row {
        padding: 12px 16px 15px 16px;
        border: 1px solid #f47c12;
        border-radius: 5px;
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);
        margin-bottom: 20px;
        p {
          line-height: 20px;
        }
        .item-name {
          font-size: 20px;
          font-weight: 700;
          text-overflow: clip;
          line-height: 45px;
          span {
            align-items: baseline;
            display: flex;
          }
        }
        .card-qc-checkbox {
          display: flex;
          float: right;
          gap: 20px;
          padding: 0px !important;
          .item-quantity {
            font-size: 20px;
            font-weight: 700;
            line-height: 45px;
          }
          .order-list-checkbox-pair {
            width: 110px;
            input {
              width: 45px;
              height: 45px;
            }

            svg {
              width: 100%;
              padding-top: 15px;
              margin-left: 10px;
            }
          }
          div {
            gap: 20px;
          }
        }
      }
    }
    .order-list::-webkit-scrollbar {
      display: none;
    }
  }
  section.bottom {
    display: flex;
    background: white;

    .clear-table,
    .view-table {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 100%;
        color: $color-white;
        line-height: normal;
      }
    }

    .clear-table {
      background: $color-orange !important;
    }

    .view-table {
      border-radius: 4px;

      button {
        color: $color-black;
      }
    }
  }

  &.done {
    section {
      &.top {
        background-color: #8e8e8e;
        color: $color-top-text;
      }
    }
    .order-list .item-row {
      border: 1px solid #9e9e9e !important;
      background-color: #ededed;
      *,
      *::before,
      *::after {
        color: #5b5b5b;
      }
    }
  }
  .no-order {
    width: auto;
    font-size: 5rem !important;
    color: white !important;
    text-align: center;
    position: relative;
    top: 50%;
  }
}
