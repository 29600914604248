.crm-demo-page {
  width: 100%;
  min-height: 1200px;
  background: #ffffff;
  font-family: 'Noto Sans KR', sans-serif;
  position: relative;
}

/* STATISTIC CARDS */
.wireframe-cards {
  position: absolute;
  top: 48px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 220px;

  .statistic-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 24px 24px;
    gap: 20px;

    flex: 1 1 0;
    min-width: 260px;
    height: 220px;
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;

    .card-top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .avatar-description {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .category-tab {
          width: 48px;
          height: 48px;
          border-radius: 100px;
          background: #d8d8d8;
        }

        .category-tab-title {
          font-weight: 600;
          font-size: 20px;
          color: #484848;
        }
      }
    }

    .card-text-block {
      width: 100%;

      .card-headline {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: #1b1a62;
      }

      .card-data {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;

        .percent-change {
          font-weight: 500;
          font-size: 14px;

          &.negative {
            color: #f71414;
          }

          &.positive {
            color: #2d88f2;
          }
        }

        .from-last-month {
          font-weight: 500;
          font-size: 14px;
          color: #6a6a6a;
        }
      }
    }
  }
}

/* "Reach Out" button */
.table-actions {
  position: absolute;
  top: 320px;
  right: 20px;

  .btn-reach-out {
    width: 160px;
    height: 44px;
    background: #fc5c0c;
    border-radius: 4px;
    font-weight: 900;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
}

/* Main container for table + pagination */
.component-6 {
  position: absolute;
  top: 370px;
  left: 20px;
  right: 20px;

  /* Wrap the table in a horizontally scrollable container */
  .table-scroll-wrapper {
    overflow-x: auto; /* horizontal scroll only for table */
    margin-bottom: 20px; /* space for pagination */
  }

  .table-container {
    /* Minimum width to accommodate columns side by side. */
    min-width: 1900px;
  }

  /* TABLE HEADER */
  .table-header-n3 {
    display: grid;
    grid-template-columns:
      60px /* checkbox */
      300px /* Customer */
      180px /* DateOfBirth */
      180px /* Visits */
      200px /* Accompanied Visits */
      180px /* Avg Order */
      180px /* Total Spent */
      220px /* Spent w/ Accomp */
      180px /* Last Visit */
      180px /* Days Since Last */
      200px /* Favorite Category */
      180px /* 대표방문요일 */
      200px /* 대표방문시간대 */
      180px /* 평균동반인원수 */
      180px /* 할인사용횟수 */
      220px /* 주문한카테고리수 */
      200px /* 추천쿠폰 */
      200px /* 최적발송시간 */
      200px /* 쿠폰유효기간 */;

    align-items: center;
    padding: 16px 40px;
    gap: 20px;
    background: #f7f7f7;
    border-radius: 4px 4px 0 0;

    .header-col {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      /* Uniform color for all headers */
      color: #666;
      text-align: center;
    }

    .check {
      justify-content: center;

      .checkbox-square {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }

  /* TABLE ROWS */
  .table-row-2 {
    display: grid;
    /* same column structure as header */
    grid-template-columns:
      60px
      300px
      180px
      180px
      200px
      180px
      180px
      220px
      180px
      180px
      200px
      180px
      200px
      180px
      180px
      220px
      200px
      200px
      200px;

    align-items: center;
    padding: 16px 40px;
    gap: 20px;
    background: #ffffff;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .row-col {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .check {
      justify-content: center;

      .checkbox-square {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    /* Column for Customer with photo + name */
    .row-col.table-cell-customer {
      gap: 10px;
      justify-content: flex-start;
      text-align: left;
      padding-left: 60px;

      .users-photo {
        width: 48px;
        height: 48px;
        background: #eff6ff;
        border-radius: 50%;
      }

      .customer-name {
        font-weight: 600;
        font-size: 16px;
        color: #212121;
      }
    }
  }

  /* Pagination container is outside the scroll-wrapper */
  .table-pagination-container {
    display: flex;
    justify-content: center;
  }
}
