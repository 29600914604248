$color-black: #000;
$color-white: #fff;
$color-orange: #f47c12;
$color-orange-01: #fb9530;
$color-orange-02: #ffa012;
$color-orange-03: #f47c12;
$color-gray-01: #bbb;
$color-gray-02: #dadada;
$color-gray-03: #999;
$color_gray_04: #212121;
$color_gray_05: #4d4d4d;
$color_gray_06: #333333;
$color_gray_07: #f2f2f2;
$color_gray_08: #dee2e6;
$color_gray_09: #ebebeb;
$color_gray_10: #c4c4c4;
$color_gray_11: #cccccc;
$color_gray_12: #c5c5c5;
$color_gray_13: #8d8d8d;
$color_gray_14: #b3b3b3;
$color_gray_15: #808080;
$color-green-01: #14cc1b;
$color-blue-01: #1c10c9;
$color-draft: #7a7876;
$color-waiting-paying: #ff1c1c;
$color-paid: #2478f6;
$color-printed: #20d432;
$color-completed: #212121;
$color-primary: #eb921e;
$color-top-text: #ffffff;
$color-tag-text: #e56c0f;
$color-tag-bg: #ffeddf;
$color-bg-default: #ffffff;
$color-text-default: #000000;
$color-hr-default: rgba(0, 0, 0, 0.16);
$color-bg-done: #737373;
$color-text-done: #ffffff;
$color-hr-done: rgba(255, 255, 255, 0.16);
$color-gray_14: #b3b3b3;
$color-button-border: #ccc;
