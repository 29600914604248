.open-business-switch-container {
  .ant-switch-handle::before {
    background-color: green !important;
  }
}

.close-business-switch-container {
  .ant-switch-handle::before {
    background-color: red !important;
  }
}
