.pause-business-switch-container {
  .ant-switch-handle::before {
    background-color: red !important;
  }
}

.non-pause-business-switch-container {
  .ant-switch-handle::before {
    background-color: gray !important;
  }
}
