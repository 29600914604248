// Define styles for the form container
.form {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
  transform: translateZ(0);
}
// Style form labels and inputs
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
// Style the submit button
button[type='submit'] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button[type='submit']:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
